import React, { useEffect, useState, useRef } from 'react';
import { Map, GeoJSON, TileLayer, useLeaflet } from 'react-leaflet';
import * as d3 from 'd3';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { image } from '../icons/iconOrt.js';
import {getCustomIcon} from '../icons/getColoredIcon'

// NetworkMap Component which loads geojson from public and styles the network map dynamically
// public is used to make it easier to update the geojson files (static makes compilation of the app
// necessary)
export default function NetworkMap({nodesPath, edgesPath}) {
    // coordinates for the map (background, nodes and edges)
    // const [oceanData, setOceanData] = useState(null);
    // const [borderData, setBorderData] = useState(null);
    const [nodeData, setNodeData] = useState(null);
    const [edgeData, setEdgeData] = useState(null);
    // controlls which edges should be displayed. If set to 2, the map only diplays edges that have
    // a weight of 2+
    const [displayWeight, setDisplayWeight] = useState(10);
    // stores the coordinates for the edges, contains the filtered data based on displayWeight
    const [displayedEdges, setDisplayedEdges] = useState(null);
    const [displayedNodes, setDisplayedNodes] = useState(null); // same as edges, but with nodes
    // Set of all places that have a displayed edge
    const [connectionsSet, setConnectionsSet] = useState(null);

    // Function to fetch GeoJSON data from the public directory
    const loadGeoJSON = async (url, setData) => {
        try {
            const response = await fetch(url);
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Error loading GeoJSON:', error);
        }
    };
    // load Geojsons on component render
    useEffect(() => {
        // Load GeoJSON files
        if (nodesPath) loadGeoJSON(nodesPath, setNodeData);
        if (edgesPath) loadGeoJSON(edgesPath, setEdgeData);
    }, [nodesPath, edgesPath]);
    // set displayedEdges to filtered edgeData if edge data is present; runs if either edgeData or
    // display weight changes. This is done so that the Weight which should be displayed can be
    // controlled dynamically by the user and will be rendered after changing
    useEffect(() => {
        if (edgeData) {
            const filteredEdges = filterEdgesByWeight(edgeData, displayWeight);
            const connectionSet = new Set();
            for (let i=0; i< filteredEdges.features.length; i++) {
                connectionSet.add(filteredEdges.features[i].properties.source)
                connectionSet.add(filteredEdges.features[i].properties.target)
            }
            setDisplayedEdges(filteredEdges);
            setConnectionsSet(connectionSet)
        }
    }, [edgeData,nodeData, displayWeight]);
    useEffect(() => {
        if (nodeData) {
            const filteredNodes = filterNodesByConnection();
            setDisplayedNodes(filteredNodes);
        }
    }, [connectionsSet]);

    const hasCommonConnection = (node, stringSet) => {
        return stringSet.has(node.properties.name);
    }

    const filterNodesByConnection = () => {
        const filteredNodes = nodeData.features.filter(node => hasCommonConnection(node, connectionsSet));
        return {
            type: "Feature",
            features: filteredNodes
        }
    }

    // filters the edges by a minimum weight so that only the edges that have a weight >= minWeight
    const filterEdgesByWeight = (edgesData, minWeight) => {
        if (!edgesData || !edgesData.features) {
            console.error('Invalid edges data');
            return { type: 'FeatureCollection', features: [] };
        }

        // Filter and sort the features based on the weight property
        const filteredFeatures = edgesData.features
            .filter(feature => feature.properties && feature.properties.weight >= minWeight)
            .sort((a, b) => a.properties.weight - b.properties.weight); // Sort by weight, ascending

        return {
            type: 'FeatureCollection',
            features: filteredFeatures,
        };
    };
    // function called by Leaflet component that will be used on every node.
    // adds a popup on each node and gives them the specified icon.
    const onEachFeatureNode = (feature, layer) => {
        if (feature.properties && feature.properties.name && feature.properties.connections && feature.properties.weight) {
            const popupContent = `
            <a href='/ansicht/ort/${feature.properties.name}' target='_blank'>${feature.properties.Ort}</a>     
            (${feature.properties.weight} Briefe)`; 
			//<b>Cluster:</b> ${feature.properties.__glayCluster}
            layer.bindPopup(popupContent);

            // Get custom icon based on the cluster property
            const customIcon = getCustomIcon(feature.properties.__glayCluster);

            // If the layer is a marker, set the custom icon
            if (layer instanceof L.Marker) {
                layer.setIcon(customIcon);
            }
        }
    };

    // WeightControl: Menu to controll the displayWeight
    const WeightControl = () => {
        const context = useLeaflet();
        const controlRef = useRef();
        const [localSliderValue, setLocalSliderValue] = useState(displayWeight);

        useEffect(() => {
            const WeightControl = L.Control.extend({
                onAdd: function(map) {
                    const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                    const form = L.DomUtil.create('form', '', container);
                    const slider = L.DomUtil.create('input', '', form);
                    const label = L.DomUtil.create('label', '', form);

                    slider.type = 'range';
                    slider.min = '1';
                    slider.max = 20;
                    slider.step = '1';
                    slider.value = localSliderValue;
                    slider.style.width = '100px';
                    label.innerHTML = `Kantengewicht: ${localSliderValue}`;

                    L.DomEvent.disableClickPropagation(container);

                    L.DomEvent.on(slider, 'input', function(e) {
                        const newValue = Number(e.target.value);
                        setLocalSliderValue(newValue);
                        label.innerHTML = `Kantengewicht: ${newValue}`;
                    });

                    L.DomEvent.on(slider, 'change', function(e) {
                        const newValue = Number(e.target.value);
                        setDisplayWeight(newValue);
                    });
                    return container;
                },
            });

            const weightControl = new WeightControl({ position: 'bottomleft' });
            const container = context.layerContainer || context.map;
            container.addControl(weightControl);
            controlRef.current = weightControl;

            return () => {
                container.removeControl(weightControl);
            };
        }, [context]);

        useEffect(() => {
            if (controlRef.current) {
                const slider = controlRef.current.getContainer().querySelector('input');
                const label = controlRef.current.getContainer().querySelector('label');
                if (slider && label) {
                    slider.value = localSliderValue;
                    label.innerHTML = `Kantengewicht: ${localSliderValue}`;
                }
            }
        }, [localSliderValue]);

        return null;
    };

    // method called by Leaflet component on each Edge.
    // adds a popup on clicking the edges
    const onEachFeatureEdge = (feature, layer) => {
        if (feature.properties && feature.properties.weight) {
            const popupContent = `<b>Kantengewicht:</b> ${feature.properties.weight}`;
            layer.bindPopup(popupContent);
        }
    };
    // method to add a Point to a Leaflet Layer
    const pointToLayer = (feature, latlng) => {
        if (feature.geometry.type === 'Point') {
            return L.marker(latlng, { icon: image });
        }
    };
    // method that generates the css color for the edges based on the weight.
    const getColor = (weight, minWeight, maxWeight) => {
        // specify the colors in the range method. Logarithmic scaling used because linear scaling
        // won't really show the difference between the edges since the bulk is around 1-4 and some
        // but very few have a weight in the thousands
        const scale = d3.scaleLog().domain([minWeight, maxWeight]).range(['#c8c8c8', '#000000']);
        return scale(weight);
    };
    // method that actually styles the edges with the getColor function
    const styleEdges = (data) => {
        let minWeight = Infinity;
        let maxWeight = -Infinity;

        data.features.forEach((feature) => {
            const weight = feature.properties.weight;
            if (weight < minWeight) minWeight = weight;
            if (weight > maxWeight) maxWeight = weight;
        });

        return (feature) => {
            const weight = feature.properties.weight;
            const color = getColor(weight, minWeight, maxWeight);
            return { color, weight:2 };
        };
    };

    return (
	
        <div style={{ display: 'flex', flexDirection: 'column', height: '92.6vh', width: '100%' }}>
            <div style={{ flex: 1 }}>
                <Map center={[51.163, 10.447]} zoom={5} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    {displayedNodes &&
                        <GeoJSON data={displayedNodes} key={JSON.stringify(displayedNodes)} onEachFeature={onEachFeatureNode} pointToLayer={pointToLayer} />}
                    {displayedEdges && <GeoJSON key={JSON.stringify(displayedEdges)} data={displayedEdges}
                                                style={styleEdges(displayedEdges)}
                                                onEachFeature={onEachFeatureEdge} />}
                    <WeightControl />					
                </Map>
            </div>
        </div>
    );
};
