import L from 'leaflet';

export const getCustomIcon = (cluster) => {
    let color;

    // Assign a color based on the cluster value
    switch (cluster) {
        case 1:
            color = '#293851';
            break;
        case 2:
            color = '#0063af';
            break;
        case 3:
            color = '#77a7cc';
            break;
        case 4:
            color = '#295927';
            break;
        case 5:
            color = '#469542';
            break;
        case 6:
            color = '#61d35c';
            break;
        case 7:
            color = '#65582c';
            break;
        case 8:
            color = '#b5a251';
            break;
        case 9:
            color = '#ddc463';
            break;
        case 10:
            color = '#753131';
            break;
        case 11:
            color = '#ba4e4e';
            break;
        case 12:
            color = '#e35f5f';
            break;
        case 13:
            color = '#583781';
            break;
        case 14:
            color = '#784bb5';
            break;
        case 15:
            color = '#9c62ed';
            break;
        default:
            color = '#303030';  // Default gray color
    }

    // Define the custom icon with the updated color
    const svgIcon = `
        <svg style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
            <g id="Padding__x26__Artboard"/>
            <g id="Icons">
                <g>
                    <path d="M23.99976,7.81445c-5.54492,0-10.05615,4.51074-10.05615,10.05566
                        c0,1.69824,0.43066,3.37109,1.24512,4.83887c0.00879,0.02344,0.02051,0.04492,0.03467,0.06641l7.76318,11.62988
                        c0.22607,0.33887,0.60449,0.54199,1.01221,0.54199c0.40723,0,0.78564-0.20312,1.01172-0.54199l7.76318-11.62988
                        c0.00928-0.01367,0.01758-0.02832,0.0249-0.04395c0.82275-1.47363,1.25781-3.15332,1.25781-4.86133
                        C34.0564,12.3252,29.54517,7.81445,23.99976,7.81445z" style="fill:${color};"/>
                    <path d="M24.00976,37.77246c-1.2334,0-3.32031,0.25391-3.32031,1.20605
                        c0,0.95312,2.08691,1.20703,3.32031,1.20703c1.23291,0,3.31982-0.25391,3.31982-1.20703
                        C27.32959,38.02637,25.24267,37.77246,24.00976,37.77246z" style="fill:#303030;"/>
                </g>
            </g>
        </svg>
    `;

    // Create an L.DivIcon with the custom SVG
    return L.divIcon({
        className: 'custom-icon',
        html: svgIcon,
        iconSize: [24, 24],
        iconAnchor: [12, 20],  // Adjust to center the icon properly
        popupAnchor: [0, -48],
    });
};

